var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',[_c('div',{staticClass:"o-auto-message-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.condition",fn:function(ref){
var item = ref.item;
return [(item.condition === 1)?_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.module.autoMessages.condition.unpaid')))]):_c('span',[_vm._v("---")])]}},{key:"item.when",fn:function(ref){
var item = ref.item;
return [(item.when === 1)?_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.module.autoMessages.trigger.afterRegistration')))]):(item.when === 2)?_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.module.autoMessages.trigger.beforEventStart')))]):_c('span',[_vm._v("---")])]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [(item.group)?_c('span',[_vm._v(_vm._s(item.group.name))]):_c('span',[_vm._v("---")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('span',[_vm._v(_vm._s(item.email))]):_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.module.autoMessages.email.userEmail')))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'panel.event.view.autoMessages.edit', params: { amid: item.id } }}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])]),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-email-arrow-right-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.testEmail.sendTestEmail')))])])]}}],null,true),model:{value:(item.dialog),callback:function ($$v) {_vm.$set(item, "dialog", $$v)},expression:"item.dialog"}},[_c('m-test-email',{attrs:{"messageId":item.message.id},on:{"close":function($event){item.dialog = false}}})],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }